import { randomId } from '../random'

const advanced = {
  name: 'advanced',
  label: 'Advanced',
  component: 'group',
  fields: [{
    label: 'Enabled',
    name: 'enabled',
    component: 'toggle'
  }, {
    label: 'Element Classes',
    name: 'elementClasses',
    component: 'group-list',
    itemProps: item => ({
      key: item.id,
      label: item.element || 'undefined element'
    }),
    defaultItem: () => ({
      id: randomId()
    }),
    fields: [{
      label: 'Element',
      name: 'element',
      component: 'text'
    }, {
      label: 'Classes',
      name: 'classes',
      component: 'tags'
    }]
  }]
}

const advancedDefaults = {
  advanced: {
    enabled: true
  }
}

export { advanced, advancedDefaults }
