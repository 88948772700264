import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo ({ description, meta, title }) {
  const { settingsJson: { siteInfo } } = useStaticQuery(
    graphql`
    {
      settingsJson(pk: {eq: "settings"}) {
        siteInfo {
          title
          author
          description
          tags
          titleSeparator
        }
        fileRelativePath
        rawJson
      }
    }    
    `
  )

  const metaDescription = description || siteInfo.description
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      titleTemplate={`%s${siteInfo.titleSeparator}${siteInfo.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: siteInfo.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(meta)}
    >
      <link href='https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css' rel='stylesheet' />
      <script src='https://s.pageclip.co/v1/pageclip.js' charset='utf-8' />
      <link rel='stylesheet' href='https://s.pageclip.co/v1/pageclip.css' media='screen' />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

export default Seo
