import { disabled } from '../general'

const CallLink = {
  label: 'Call Link',
  key: 'callLink',
  fields: [{
    name: 'icon',
    label: 'Icon',
    component: 'group',
    fields: [
      disabled
    ]
  }, {
    name: 'label',
    label: 'Label',
    component: 'group',
    fields: [{
      name: 'text',
      label: 'Text',
      component: 'group',
      fields: [
        disabled
      ]
    }, {
      name: 'phoneNumber',
      label: 'Phone Number',
      component: 'group',
      fields: [
        disabled
      ]
    },
    disabled
    ]
  },
  disabled
  ]
}

export { CallLink }
