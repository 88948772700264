import { advanced, advancedDefaults } from '../general'

const SearchButton = {
  label: 'Search Button',
  key: 'searchButton',
  defaultItem: () => ({
    ...advancedDefaults
  }),
  fields: [advanced]
}

export { SearchButton }
