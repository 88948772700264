import React from 'react'

import styles from './searchButton.module.scss'

const SearchButtonBlock = ({ block, search }) => block.advanced.enabled ? (
  <div className={styles.search}>
    <img className={styles.searchIcon} src={search} alt='search' />
  </div>
) : null

export { SearchButtonBlock }
