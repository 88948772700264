import React from 'react'
import { Link } from 'gatsby'

import styles from './logo.module.scss'

const LogoBlock = ({ block, logo }) => {
  return !block.disabled ? (
    <Link className={styles.link} to='/'>
      <img className={styles.logo} src={logo} />
    </Link>
  ) : null
}

export { LogoBlock }
