import React from 'react'
import { withPlugin } from 'tinacms'
import { JsonCreatorPlugin } from 'gatsby-tinacms-json'
import slugify from 'slugify'

import Seo from '../seo/seo'
import Settings from '../../settings'
import { Footer, Header, Main } from '../'

import './base.scss'
import styles from './layout.module.scss'

const Layout: React.FC<any> = ({ children, title, description, disableFooter, textColor, backgroundColor }) => {
  return (
    <div className={styles.div}>
      <Seo title={title} description={description} />
      <Settings />
      <Header />
      <div className={styles.body} style={{ color: textColor, backgroundColor: backgroundColor }}>
        <Main>
          {children}
        </Main>
        {!disableFooter && <Footer />}
      </div>
    </div>
  )
}

const CreatePagePlugin = new JsonCreatorPlugin({
  label: 'Page',
  filename: form => {
    return `content/pages/${slugify(form.title.toLowerCase())}.json`
  },
  data: form => ({
    title: 'Untitled',
    description: 'No description provided.',
    ...form,
    slug: slugify(form.title.toLowerCase())
  }),
  fields: [{
    name: 'title',
    component: 'text',
    label: 'Title',
    validate: value => {
      if (!value) return 'The page title is required.'
    }
  }, {
    name: 'description',
    component: 'textarea',
    label: 'Description',
    validate: value => {
      if (!value) return 'The page description is required.'
    }
  }]
})

export default withPlugin(Layout, CreatePagePlugin)
