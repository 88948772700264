import { useStaticQuery, graphql } from 'gatsby'
import { useGlobalJsonForm } from 'gatsby-tinacms-json'
import Color from 'color'
import React, { useState, useEffect } from 'react'
import { FiSunrise, FiSunset } from 'react-icons/fi'

import { advanced, advancedDefaults, disabled } from './general'
import { CallLink, Fill, SearchButton } from './header'
import { randomColor, randomId } from './random'

const Settings = () => {
  const [dark, setDark] = useState(false)
  const { settingsJson, allPagesJson: { nodes: pages } } = useStaticQuery(graphql`
  {
    settingsJson(pk: {eq: "settings"}) {
      siteInfo {
        darkMode {
          darkModeToggle
        }
      }
      assets {
        assets {
          asset {
            publicURL
            relativePath
          }
          name
        }
      }
      theme {
        colorVariables {
          id
          variable
          light
          dark
        }
        customProperties {
          id
          property
          value
        }
      }
      fileRelativePath
      rawJson
      layout {
        header {
          blocks {
            disabled
            _template
          }
        }
      }
    }
    allPagesJson {
      nodes {
        title
        slug
      }
    }
  }  
  `)

  const imageUpload = subdirectory => {
    return {
      label: 'Asset',
      name: 'asset',
      component: 'image',
      parse: filename => `../images/${subdirectory}/${filename}`,
      uploadDir: () => `/content/images/${subdirectory}/`,
      previewSrc: (formValues, fieldProps) => {
        const assets = settingsJson.assets
        const image = assets && assets.assets && assets.assets.length && fieldProps.input && fieldProps.input.value ? assets.assets.find(asset => asset.asset && fieldProps.input.value.includes(asset.asset.relativePath)) : ''
        if (!image || !image.asset) return ''
        if (!image.asset.childImageSharp) return image.asset.publicURL
        return image.asset.childImageSharp.fluid.src
      }
    }
  }

  const Navigation = {
    label: 'Navigation Menu',
    key: 'navigationMenu',
    defaultItem: () => ({
      ...advancedDefaults
    }),
    fields: [{
      label: 'Links',
      name: 'links',
      component: 'group-list',
      itemProps: item => ({
        key: item.id,
        label: pages.find(page => page.slug === item.link) ? pages.find(page => page.slug === item.link).title : 'Undefined Link'
      }),
      defaultItem: () => ({
        id: randomId(),
        link: '/',
        style: 'Default',
        ...advancedDefaults
      }),
      fields: [{
        name: 'link',
        label: 'Link',
        component: 'select',
        options: pages.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase ? -1 : 1).map(page => page.slug)
      }, {
        name: 'style',
        label: 'Style',
        component: 'select',
        options: ['Default', 'Favorite', 'Button']
      },
      advanced]
    },
    advanced]
  }

  const Logo = {
    label: 'Logo',
    key: 'logo',
    fields: [{
      label: 'Logo',
      name: 'logo',
      component: 'select',
      options: settingsJson.assets.assets.map(asset => asset.name)
    }, disabled]
  }

  const [{ theme }] = useGlobalJsonForm(settingsJson, {
    label: 'Settings',
    fields: [{
      name: 'rawJson.siteInfo',
      label: 'Site Info',
      component: 'group',
      fields: [{
        name: 'title',
        label: 'Site Title',
        component: 'text'
      }, {
        name: 'titleSeparator',
        label: 'Page/Site Title Separator',
        component: 'text'
      }, {
        name: 'author',
        label: 'Author Name',
        component: 'text'
      }, {
        name: 'description',
        label: 'Site Description',
        component: 'textarea'
      }, {
        name: 'tags',
        label: 'Meta Tags',
        component: 'tags'
      }, {
        name: 'darkMode',
        label: 'Dark Mode',
        component: 'group',
        fields: [{
          name: 'darkModeToggle',
          label: 'Show Dark Mode Toggle',
          component: 'toggle'
        }, {
          name: 'darkModeAuto',
          label: 'Automatic Dark Mode',
          component: 'toggle'
        }]
      }]
    }, {
      name: 'rawJson.assets',
      label: 'Assets',
      component: 'group',
      fields: [{
        name: 'assets',
        label: 'Assets',
        component: 'group-list',
        itemProps: item => ({
          key: item.id,
          label: item.name || 'Unnamed Asset'
        }),
        defaultItem: () => ({
          id: randomId()
        }),
        fields: [{
          label: 'Name',
          name: 'name',
          component: 'text'
        },
        imageUpload('site')]
      }]
    }, {
      name: 'rawJson.theme',
      label: 'Theme',
      component: 'group',
      fields: [{
        name: 'colorVariables',
        label: 'Color Variables',
        component: 'group-list',
        itemProps: item => ({
          key: item.id,
          label: item.variable
        }),
        defaultItem: () => {
          const id = randomId()
          return ({
            variable: `--random-color-${id}`,
            id,
            light: randomColor(),
            dark: randomColor()
          })
        },
        fields: [{
          label: 'Variable Name',
          name: 'variable',
          component: 'text',
          description: 'The variable name should be --formatted-like-this'
        }, {
          label: 'Light Mode Color',
          name: 'light',
          component: 'color',
          parse: value => value || '#ffffff'
        }, {
          label: 'Dark Mode Color',
          name: 'dark',
          component: 'color',
          parse: value => value || '#ffffff'
        }]
      }, {
        name: 'customProperties',
        label: 'Custom Properties',
        component: 'group-list',
        itemProps: item => ({
          key: item.id,
          label: item.property || '--no-property-name'
        }),
        defaultItem: () => {
          const id = randomId()
          return ({
            property: '--custom-property',
            id,
            value: 'value'
          })
        },
        fields: [{
          label: 'Property',
          name: 'property',
          component: 'text',
          description: 'The property name should be --formatted-like-this',
          parse: value => value || '--no-property-name'
        }, {
          label: 'Value',
          name: 'value',
          component: 'text',
          parse: value => value || 'no value set'
        }]
      }]
    }, {
      name: 'rawJson.layout',
      label: 'Layout',
      component: 'group',
      fields: [{
        name: 'header',
        label: 'Header',
        component: 'group',
        fields: [{
          name: 'blocks',
          label: 'Header Blocks',
          component: 'blocks',
          templates: { Logo, Fill, Navigation, CallLink, SearchButton }
        },
        disabled
        ]
      }, {
        name: 'main',
        label: 'Main',
        component: 'group',
        fields: [disabled]
      }, {
        name: 'footer',
        label: 'Footer',
        component: 'group',
        fields: [disabled]
      }]
    }]
  })

  const toggleDark = () => setDark(!dark)

  useEffect(() => {
    const mode = dark ? 'dark' : 'light'
    const style = document.documentElement.style
    style.setProperty('--mode', mode)
    theme.colorVariables.forEach(({ variable, light, dark }) => {
      style.setProperty(variable, mode === 'light' ? light : dark)
      style.setProperty(variable + '-dark', Color(mode === 'light' ? light : dark).darken(0.2).hex())
      style.setProperty(variable + '-light', Color(mode === 'light' ? light : dark).lighten(0.2).hex())
    })
    theme.customProperties.forEach(({ property, value }) => {
      style.setProperty(property, value)
    })
  }, [theme, dark])

  return settingsJson.siteInfo.darkMode.darkModeToggle && (
    <div style={{ position: 'fixed', bottom: '1rem', left: '1rem', zIndex: 5 }}>
      {dark ? <FiSunrise onClick={toggleDark} /> : <FiSunset onClick={toggleDark} />}
    </div>)
}

export default Settings
