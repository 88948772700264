import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styles from './footer.module.scss'

const Footer = () => {
  const { settingsJson: { layout: { footer: { disabled } } } } = useStaticQuery(graphql`
  {
    settingsJson(pk: {eq: "settings"}) {
      layout {
        footer {
          disabled
        }
      }
    }
  }
`)

  return !disabled ? (
    <footer className={styles.footer}>
      Footer
    </footer>
  ) : null
}

export default Footer
