import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styles from './main.module.scss'

const Main: React.FC<any> = ({ children }) => {
  const { settingsJson: { layout: { main: { disabled } } } } = useStaticQuery(graphql`
  {
    settingsJson(pk: {eq: "settings"}) {
      layout {
        main {
          disabled
        }
      }
    }
  }
`)

  return !disabled ? (
    <main className={styles.main}>
      {children}
    </main>
  ) : null
}

export default Main
