import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ResizeObserver from 'rc-resize-observer'

import { CallLinkBlock, FillBlock, LogoBlock, NavigationBlock, SearchButtonBlock } from '../../blocks/header'

import styles from './header.module.scss'

const Header = () => {
  const { settingsJson: { assets: { assets }, layout: { header: { blocks, disabled } } }, allPagesJson: { nodes: pages } } = useStaticQuery(graphql`
  {
    settingsJson(pk: {eq: "settings"}) {
      assets {
        assets {
          id
          name
          asset {
            publicURL
            name
          }
        }
      }
      layout {
        header {
          disabled
          blocks {
            _template
            links {
              id
              link
              style
              advanced {
                enabled
              }
            }
            advanced {
              enabled
            }
            logo
            disabled
            icon {
              disabled
            }
            label {
              disabled
              phoneNumber {
                disabled
              }
              text {
                disabled
              }
            }
          }
        }
      }
    }
    allPagesJson {
      nodes {
        title
        slug
      }
    }
  }  
`)

  const [headerHeight, setHeaderHeight] = useState(0)

  const sizeListener = () => {
    const style = document.documentElement.style
    style.setProperty('--window-height', window.innerHeight + 'px')
    style.setProperty('--header-height', headerHeight + 'px')
    style.setProperty('--main-height', window.innerHeight - headerHeight + 'px')
    style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }

  useEffect(() => {
    if (blocks.length) {
      const gridTemplateColumns = blocks.map(block => {
        if (block._template === 'Logo') return '19.5vw'
        if (block._template === 'Fill') return '1fr'
        return 'auto'
      })
      document.documentElement.style.setProperty('--header-grid-template-columns', gridTemplateColumns.join(' '))
    }
  }, [blocks])

  useEffect(() => {
    sizeListener()
  }, [headerHeight])

  useEffect(() => {
    sizeListener()
    window.addEventListener('resize', sizeListener)
    return () => {
      window.removeEventListener('resize', sizeListener)
    }
  }, [])

  return !disabled ? (
    <ResizeObserver onResize={() => setHeaderHeight(document.getElementsByTagName('header')[0].offsetHeight)}>
      <header className={styles.header}>
        {blocks.map((block, i) => {
          if (block._template === 'Logo' && block.logo) {
            return (
              <LogoBlock key={i} block={block} logo={assets.find(asset => asset.name === block.logo).asset.publicURL} />
            )
          } else if (block._template === 'Fill') {
            return (
              <FillBlock key={i} block={block} />
            )
          } else if (block._template === 'Navigation') {
            return (
              <NavigationBlock key={i} block={block} pages={pages} heart={assets.find(asset => asset.name === 'Heart').asset.publicURL} />
            )
          } else if (block._template === 'CallLink') {
            return (
              <CallLinkBlock key={i} block={block} phone={assets.find(asset => asset.name === 'Phone').asset.publicURL} />
            )
          } else if (block._template === 'SearchButton') {
            return (
              <SearchButtonBlock key={i} block={block} search={assets.find(asset => asset.name === 'Search').asset.publicURL} />
            )
          } else {
            return (
              <p key={i}>{block._template}</p>
            )
          }
        })}
      </header>
    </ResizeObserver>
  ) : null
}

export default Header
