import React from 'react'
import { Link } from 'gatsby'

import styles from './navigation.module.scss'

const NavigationBlock = ({ block, pages, heart }) => block.advanced.enabled ? (
  <nav className={styles.nav}>
    {block.links.length && (
      <ul className={styles.links}>
        {block.links.map(({ link, id, style, advanced }) => link && id && advanced && advanced.enabled && (
          <li key={id} className={styles.linkContainer + ' ' + styles[style.toLowerCase()]}>
            {style === 'Favorite' && <img className={styles.favoriteIcon} src={heart} alt='favorite' />}
            <Link className={styles.link} activeClassName={styles.active} to={link[0] === '/' ? link : '/' + link}>
              {pages.find(page => page.slug === link).title || 'Page Not Found'}
              {style === 'Default' && <span className={styles.bullet} />}
            </Link>
          </li>
        ))}
      </ul>
    )}
  </nav>
) : null

export { NavigationBlock }
