import React from 'react'

import styles from './callLink.module.scss'

const CallLinkBlock = ({ block, phone }) => (!block.disabled ? (
  <a className={styles.link} href='tel:+18001234567'>
    {!block.icon.disabled && (
      <img className={styles.image} src={phone} alt='phone' />
    )}
    {!block.label.disabled && (
      <div className={styles.label}>
        {!block.label.text.disabled && (
          <p className={styles.text}>Get help now</p>
        )}
        {!block.label.phoneNumber.disabled && (
          <p className={styles.phoneNumber}>800-123-4567</p>
        )}
      </div>
    )}
  </a>
) : null)

export { CallLinkBlock }
